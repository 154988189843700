.banner-contact{
    margin: 40px 0px;
    background-image: url("../Images/Contact/1.jpg");
    padding: 130px 0px;
    text-align: center;
    background-color: white;
    background-blend-mode: multiply;
}

.banner-contact div{
    mix-blend-mode: multiply;
}

.banner-contact h2{
    font-size: 45px;
    color: #ffa200;
}

.banner-contact p{
    font-size: 19px;
    color: rgb(0, 0, 0);
}


#contact-details{
    padding: 40px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#contact-details .details{
    width: 40%;
    display: flex;
    flex-direction: column;
}

#contact-details .details  span,
#form-details form span{
    font-size: 12px;
    color: #F18F43;
}

#contact-details .details h2,
#form-details form h2{
    font-size: 26px;
    line-height: 35px;
    padding: 20px 0;
}

#contact-details .details h3{
    font-size: 16px;
    padding-bottom: 15px;
}

#contact-details .details li{
    list-style: none;
    display: flex;
    padding: 10px 0;
    gap: 20px;
}

.icons-contact{
    color: #F18F43;
}


#contact-details .details li p{
    font-size: 17px;
    margin: 0;
}


#contact-details .map{
    width: 55%;
    height: 400px;
}


#contact-details .map iframe{
    width: 100%;
    height: 100%;
    border:0;
}

#form-details{
    padding: 40px 80px;
    display: flex;
    justify-content: space-between;
    margin: 30px;
    padding: 80px;
    border: 1px solid #e1e1e1;
}

#form-details form{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#form-details form input,
#form-details form textarea{
    width: 100%;
    padding: 12px 15px;
    outline: none;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
}

#form-details form button{
    background-color: #088178;
    color: whitesmoke;
    font-size: 15px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 2px;
    border-color: #016a63;
    position: relative;
    font-weight: bolder;
    outline: none;
    white-space: nowrap;
}

#form-details form button:hover{
    background: #F18F43;
    border: 1px solid #F18F43;
    color: black;
}

#form-details .people div{
    position: relative;
    padding-bottom: 25px;
    display: flex;
    align-items: flex-start;
    top: 80px;
}

#form-details .people div img{
    position: relative;
    width: 65px;
    height: 65px;
    object-fit: cover;
    right: 12px;
    top: 30px;
    border-radius: 50px;
}

#form-details .people div p{

    margin: 0;
    font-size: 13px;
    line-height: 25px;
}


#form-details .people div p span{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: black;
}