@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&family=Monoton&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color:white;
}

