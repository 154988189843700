/*  Head Banner   */
.head-banner {
  margin-top: 40px;
  margin-bottom: 90px;
  padding: 100px 0;
  background-image: url('../Images/Banner/16.avif');
  background-size: cover;
}

.head-banner h1 {
  mix-blend-mode: multiply;
  text-align: center;
  font-size: 50px;
  color: black;
}

.products {
  display: flex;
  overflow: hidden;
}

.products .other{
  width: 85%;
}

.products .sidebar {
  width: 15%;
  text-align: center;
  background-color: #201f1f;
  margin-left: 19px;
  height: 400px;
  margin-top: 100px;
  box-shadow: 20px  20px 24px #918787;
}

.sidebar h1 {
  margin-top: 100px;
  font-size: 25px;
  margin-bottom: 0px;
  position: relative;
  background-color: inherit;
  color: #ffcb74;
}
.sidebar aside {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
  background-color: inherit;
  font-size: 17px;
 
}

.sidebar aside a{
    color: rgb(255, 255, 255);
}

aside a:hover{
    color: #ffcb74;
}

aside a.active{
    color: #ffcb74;
}


/* Banner  */
.product-banner{
    background-image: url('../Images/Banner/6.webp');
    padding: 180px 0px;
    background-size: cover;
    margin: 60px 0px;
    background-position: center right;
    overflow: hidden;
}

.product-banner h1,
.product-banner p,
.product-banner button{
    mix-blend-mode: multiply;
    text-align: center;
}

.product-banner h1{
    font-size: 50px;
    position: relative;
    left: 450px;
    bottom: 50px;
}

.product-banner p{
    font-size: 25px;
    position: relative;
    left: 450px;
    bottom: 35px;
}

.product-banner  span{
    color:rgb(253, 56, 7);
}

.product-banner button {
    position: relative;
    margin-left: 66em;
    bottom: 28px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bolder;
    color: white;
    background-color: rgb(253, 56, 7);
    border-color: white;
  }
  
  .product-banner button:hover {
    color: white;
    background-color: black;
    border-color: black;
  }