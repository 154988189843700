a {
  text-decoration: none;
  color: black;
  font-weight: bolder;
  background-color: inherit;
}

.nav a:hover {
  color: rgb(215, 127, 11);
}

.nav nav .active,
.nav .active {
  color: #ffcb74;
}

header {
  position: sticky;
  top: 0;
  padding: 3px 0;
  background-color: #2f2f2f;
  z-index: 99999;
}

.logo {
  position: relative;
  font-family: 'Monoton', Courier;
  font-weight: bolder;
  color: #ffcb74;
  font-size: 30px;
  background-color: inherit;
}

.nav {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  font-size: 20px;
}

.nav nav{
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  background-color: inherit;
}

.nav nav a {
  padding: 1rem;
  color: white;
}

nav,
.link {
    background-color: inherit;
}

.cart-count{
  background-color: inherit;
}
.count{
  background-color: red;
  padding: 0;
  width: 12px;
  position: absolute;
  font-size: 15px;
  border-radius: 50%;
  left: 480px;
  top: 2px;
  text-align: center;
}

.count:hover{
  color: white;
}

.auth-link{
  background-color: inherit;
  position: relative;
  margin-top: 10px;
}

.auth-button{
  background-color: inherit;
  margin-left: 50px;
}

.auth-button button{
  cursor: pointer;
  padding: 10px;
  width: 120px;
  color: #ee9b0c;
  background-color: inherit;
  border-color: #ee9b0c;
  font-size: large;
  margin-left: 40px;
}

.auth-button button:hover{
  border-color: #2f2f2f;
}

.auth-button span{
  background-color: inherit;
  color: #ee9b0c;
  font-size: 18px;
}