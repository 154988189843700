.about-banner{
    background-image: url("../Images/About/1.png");
    padding: 110px 0px;
    margin: 40px 0px;
    text-align: center;
    background-position: right center;
}

.about-banner h1{
    mix-blend-mode: multiply;
    font-size: 65px;
    color: #f7af34;
}

#about-head{
    padding: 40px 80px;
    display: flex;
    align-items: center;
}

#about-head img{
    width: 50%;
    height: auto;
}

#about-head p{
    font-size: 19px;
}

#about-head h1{
    font-size: 30px;
    color: #f7af34;
}

#about-head div{
    padding-left: 40px;
}


#about-app{
    text-align: center;
}

#about-app h1{
    font-size: 50px;
    color: #f7af34;
}

#about-app  .video{
    width: 50%;
    height: 100%;
    margin: 30px auto 60px auto;
}

#about-app .video video{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

